import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash,faXmark, faSpinner } from '@fortawesome/free-solid-svg-icons';
import toast, { Toaster } from "react-hot-toast";
import {useDispatch} from 'react-redux'
import { useSelector } from 'react-redux';
import { setDoctorEmail } from "../store";
import axios from 'axios'
import { BASE_URL } from "../config";

export default function DoctorLogin() {

    const [passwordVisible, setPasswordVisible] = useState(false);
    const [password, setPassword] = useState('');

    const [username, setUsername] = useState("");
    console.log("username",username, password)
    const [forgotpassword, setForgotPassword] = useState(false);
    const [checkmail, setCheckMail] = useState(false) 

  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState('');


    const handleTogglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const dispatch = useDispatch()


const handleSubmit = async (e) => {   
  e.preventDefault();
  try {
      const response = await axios.post(`${BASE_URL}/api/doctorLogin`, {
          email: username,
          password,
      });
      if (response.data.status === "success") {
          toast.success("Login Successful");
          dispatch(setDoctorEmail(username));
          setTimeout(() => {
              window.location.href = "/Doctor/DoctorDashboard";
          }, 500);
      } else if (response.data.status === "blocked") {
          toast.error("Sub Admin Profile has been Blocked, can't able to Login");
          setTimeout(() => {
              window.location.href = "/Doctor/DoctorLogin";
          }, 500);
      } else {
          toast.error("Login Failed");
          setTimeout(() => {
              window.location.href = "/Doctor/DoctorLogin";
          }, 500);
          console.error("Login failed:", response.data.error);
      }
  } catch (error) {
      toast.error("Login Failed");
      console.error("Error during login:", error.response.data.error);
  }
};

const handleInput = (e) => {
    setEmail(e.target.value);
  };
  const handleOpenModal = () => {
    setForgotPassword(false);
    setCheckMail(!checkmail)
  };

const handleResetPassword = async () => {
    if(!email){
      toast.error("Enter your email");
      return
    }
    setLoading(true); 
    try {
      const emailExistsResponse = await axios.post(`${BASE_URL}/api/check_email_exists`, { email , role:"doctor" });
            console.log("emailExistsResponse",emailExistsResponse)
      if (emailExistsResponse.data.status === 'success') {
        const sendVerificationResponse = await axios.post(`${BASE_URL}/api/emailVerification`, { email,role:"doctor"  });
        if (sendVerificationResponse.data.status === "success") {
          toast.success(sendVerificationResponse.data.message);
          setCheckMail(true)
          setForgotPassword(false)

        } else {
          toast.error('Failed to send verification email. Please try again.');
        }
      } else {
        toast.error('Your email is not Registered');
      }
    } catch (error) {
    //  console.error('Error during forget password:', error.message);

      if (error.response && error.response.status === 429) {
        toast.error(error.response.data.error); 
      } else {
        toast.error('An error occurred. Please try again.');
      }
    }
    finally {
      setLoading(false); 
    }

    
    // toast.success(`A reset link has been sent to ${email}.`);
  };

  

    return (
        <div className='font-poppins bg-cover md:h-screen py-10 bg-no-repeat text-white flex flex-col md:flex-row gap-10 md:gap-0' style={{ backgroundImage: 'url("/assets/bg3.png")' }}>
            <Toaster />
            <div className='md:w-[50%] md:ml-auto '>
                <div className='relative h-full'>
                    <div className='flex items-end h-full md:pl-10 '>
                        <img src='/assets/decoration.png' className='h-[75vh] '></img>
                    </div>
                    <div className='bg-white/25 backdrop-blur-md border border-[#E4E4E4BF] rounded-2xl p-10 absolute inset-0 w-[90%] md:w-[70%] mx-auto my-5'>
                        <h1 className='text-5xl font-bold mt-2'>Hello Doctor!</h1>
                        <p className=' font-plus text-3xl leading-[3rem] my-5'>Explore Health Profile</p>
                        <hr></hr>
                    </div>
                </div>
            </div>
            <div className='md:w-[40%] md:mr-auto flex items-center'>
                <div className='flex flex-col mx-auto w-[90%] md:w-[95%]'>
                    <div className='flex justify-start mb-5'>
                        <div className='absolute bg-center z-0 rounded-full w-[193px] h-[60px] blur-lg' style={{ backgroundImage: 'url("/assets/Ellipse 276.png")' }}>
                        </div>
                        <img src='/assets/Darklogo.png' className='z-20'></img>
                    </div>
                    <label className='font-bold '>Email</label>
                    <input
                        type='email'
                        className='mt-2 mb-5 py-2 pl-7 rounded-lg border bg-white/10 outline-none'
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <label className='font-bold '>Password</label>
                    <div className='mt-2 flex border bg-white/10 rounded-lg'>
                        <input
                            type={passwordVisible ? 'text' : 'password'}
                            className='bg-transparent w-[93%] rounded-lg text-white outline-none py-2 pl-7'
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <FontAwesomeIcon
                            icon={passwordVisible ? faEye : faEyeSlash}
                            className="text-gray-300 text-xl mt-3 cursor-pointer"
                            onClick={handleTogglePasswordVisibility}
                        />
                    </div>
                    <div className='flex justify-between mt-5 px-3 text-white md:text-[#898CA9] text-sm font-bold'>
                        <div className='flex gap-2 '>
                            <div><input type='checkbox'></input></div>
                            <p>Save Email and Password</p>
                        </div>
                      
                        <button onClick={() => setForgotPassword(true) } >Forgot password !</button>

                    </div>
                    <div className='mt-5 flex justify-center md:justify-start'>
                        <button onClick={handleSubmit} className='px-10 md:px-20 py-3 text-sm font-bold rounded-xl bg-gradient-to-br from-[#3AB6FF] via-[#004696] to-[#FF3131] to-80%'>Login</button>
                    </div>

                    {forgotpassword && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-10 backdrop-blur-md z-50">
    <div className="relative bg-white/25 rounded-2xl p-8 w-full max-w-sm md:max-w-md lg:max-w-lg shadow-lg">
      {/* Close Button */}
      <button
        onClick={() => setForgotPassword(false)}
        className="absolute top-3 right-4 text-white text-xl"
      >
        <FontAwesomeIcon icon={faXmark} />
      </button>

      {/* Content */}
      <div className="flex flex-col items-center">
        <label className="text-white font-bold text-lg mb-2 self-start">
          Enter Email
        </label>
        <input
          type="text"
          name="email"
          onChange={handleInput}
          required
          className="w-full px-4 py-2 bg-transparent border border-gray-300 text-white rounded-lg text-sm focus:outline-none"
        />

        {/* Reset Button */}
        <button
          onClick={handleResetPassword}
          className="w-full mt-5 px-6 py-3 text-white font-bold rounded-xl bg-gradient-to-br from-[#3AB6FF] via-[#004696] to-[#FF3131] to-80% flex justify-center items-center"
          disabled={loading}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
          ) : (
            "Reset Password"
          )}
        </button>
      </div>
    </div>
  </div>
)}
          {checkmail && (
             <div className=' rounded-2xl z-50 w-full modal-overlay fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-10 backdrop-blur-md'>
              <div className='lg:w-[30%] flex flex-col justify-center bg-white/25 p-10 rounded-lg relative'>
                <p className='text-white text-center'>Check your mail</p>
                <div className='flex justify-between gap-10'>
               
                <a href="https://mail.google.com" 
                 className='w-[50%] text-white mt-5 px-5 py-2 text-sm font-bold rounded-xl bg-gradient-to-br from-[#3AB6FF] via-[#004696] to-[#FF3131] to-80%'
                target="_blank">
             <p className='text-center'> Check Mail </p>    
                </a>
              
                <button
                  onClick={handleOpenModal}
                  className='w-[50%] text-white mt-5 px-5 py-2 text-sm border font-bold rounded-xl '
                >
                  Close
                </button>
                </div>
              </div>
              </div>
          )}
                </div>
            </div>
        </div>
    )
}
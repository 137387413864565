import React, { useState, useEffect } from "react";
import "../App.css";
import axios from "axios"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faSun,
  faMoon,
  faSpinner,
  faXmark
} from "@fortawesome/free-solid-svg-icons";
import toast, { Toaster } from "react-hot-toast";
import {useDispatch} from 'react-redux'
import { useSelector } from 'react-redux';
import { setAdminEmail } from "../store";

import { BASE_URL } from "../config";
const Login = () => {
  const [isDarkMode, setIsDarkMode] = useState(true);
  const [username, setUsername] = useState("");
  console.log("username",username)
  const [password, setPassword] = useState("");
  const [forgotpassword, setForgotPassword] = useState(false);
  const [checkmail, setCheckMail] = useState(false) 
  const [email, setEmail] = useState('');

  const [loading, setLoading] = useState(false);

  const [visible, setVisible] = useState(false);

  const toggleDarkMode = () => {
    setIsDarkMode(!isDarkMode);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };



  const handleOpenModal = () => {
    setForgotPassword(false);
    setCheckMail(!checkmail)
  };


  const handleInput = (e) => {
    setEmail(e.target.value);
  };


  const handlepassword = () => {
    setVisible(!visible);
  };

  // Function to handle form submission
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     console.log("Username:", username);
//     console.log("Password:", password);
//     // You can add your logic here to send the username and password to the server
//   };
const dispatch = useDispatch()
const handleSubmit = async (e) => {   
  e.preventDefault();
  try {
      const response = await axios.post(`${BASE_URL}/api/login`, {
          email: username,
          password,
      });
      if (response.data.status === "success") {
          toast.success("Login Successful");
          dispatch(setAdminEmail(username));
          setTimeout(() => {
              window.location.href = "/Admin/Dashboard";
          }, 500);
      } else if (response.data.status === "blocked") {
          toast.error(" Admin Profile has been Blocked, can't able to Login");
          setTimeout(() => {
              window.location.href = "/Admin/Login";
          }, 500);
      } else {
          toast.error("Access denied only Admins can login in ");
          setTimeout(() => {
              window.location.href = "/Admin/Login";
          }, 500);
          console.error("Login failed:", response.data.error);
      }
  } catch (error) {
    toast.error("Access denied only Admins can login in ");
      console.error("Error during login:", error.response.data.error);
  }
};



const handleResetPassword = async () => {
  if(!email){
    toast.error("Enter your email");
    return
  }
  setLoading(true); 
  try {
    const emailExistsResponse = await axios.post(`${BASE_URL}/api/check_email_exists`, { email, role:"admin" });
          console.log("emailExistsResponse",emailExistsResponse)
    if (emailExistsResponse.data.status === 'success') {
      const sendVerificationResponse = await axios.post(`${BASE_URL}/api/emailVerification`, { email, role:"admin" });
      if (sendVerificationResponse.data.status === "success") {
        toast.success(sendVerificationResponse.data.message);
        setCheckMail(true)
        setForgotPassword(false)

      } else {
        toast.error('Failed to send verification email. Please try again.');
      }
    } else {
      toast.error('Your email is not Registered');
    }
  } catch (error) {
  //  console.error('Error during forget password:', error.message);

    if (error.response && error.response.status === 429) {
      toast.error(error.response.data.error); 
    } else {
      toast.error('An error occurred. Please try again.');
    }
  }
  finally {
    setLoading(false); 
  }

  
  // toast.success(`A reset link has been sent to ${email}.`);
};
  


  return (
    <div className={` ${isDarkMode ? 'bg-[#05295A]' : 'bg-[#3ab7ff28]' } bg-cover bg-repeat  relative `}>
    <Toaster />
      <div>
        <FontAwesomeIcon
          icon={isDarkMode ? faMoon : faSun}
          className="text-yellow-500 text-3xl cursor-pointer absolute top-3 left-3"
          onClick={toggleDarkMode}
        />
      </div>
      <div className={` flex justify-center items-center  ${isDarkMode ? 'text-white' : 'text-[#05295A]' } min-h-screen px-5  `}>
        {isDarkMode ?
          <div className="">
            <img
              src="../assets/Ellipse 271(1).png"
              className="w-5/12 absolute top-0 right-0"
            />
            <img
              src="../assets/Ellipse 276.png"
              className="absolute bottom-0 right-0"
            />
          </div>
          :

          ''

        }

        <div className="md:w-[90%] pt-14 gap-10 md:gap-0 flex justify-center items-center lg:flex-row flex-col  ">
          <div className=" lg:w-[55%] w-full h-full flex justify-center items-center ">
            <div className="relative">
              <div className="">
                <img src="../assets/decoration.png" className="w-9/12" />
              </div>

              <div className={` ${isDarkMode ? 'bg-[#B7E9F640]' : 'bg-[#EDEDED]' } sm:w-[85%] h-[95%] p-10 flex justify-center items-center   absolute top-0 sm:right-10  border border-white/50 rounded-xl backdrop-blur `}>
                <div className="absolute z-30 top-0 left-0 md:p-5 p-3">
                  {isDarkMode ?
                    <img
                      src="../assets/Adminlogo.png"
                      className="md:w-10/12 w-8/12"
                    />
                    :
                    <img src="../assets/Logo.png"
                      className="md:w-10/12 w-8/12"
                    />
                  }
                </div>
                <div className=" mx-auto">
                  <p className="lg:text-5xl text-4xl font-bold font-inter lg:leading-[4rem]">
                    Welcomes You Admin
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className=" lg:w-[40%] sm:w-[80%] w-full flex justify-center ">
            <div className="">
              <div className={` sm:px-10 px-5 py-7 2xl:py-14 font-notosans rounded-xl shadow-2xl border-white/20 border backdrop-blur-xl ${isDarkMode ? 'bg-gradient-to-b from-[#BFBFBF10] to-[#00000024]' : 'bg-[#EDEDED]' }  `}>
                <div>
                  <p className="font-semibold  text-3xl 2xl:text-5xl">Login</p>
                  <p className="text-sm 2xl:text-base 2xl:mt-2">
                    Glad you’re back.!
                  </p>
                </div>
                <div className="mt-5">
                  <input
                    type="text"
                    placeholder="Username"
                    className={`bg-transparent focus:outline-none border ${isDarkMode ? 'border-white/60' : 'border-[#05295A]' }  rounded-lg w-full px-4 py-1.5 md:text-base text-sm `}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                </div>
                <div className="mt-5 relative ">
                  <input
                    type={visible ? "text" : "password"}
                    placeholder="Password"
                    className={` bg-transparent focus:outline-none border  ${isDarkMode ? 'border-white/60' : 'border-[#05295A]' } rounded-lg w-full px-4 py-1.5 md:text-base text-sm`}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <button
                    onClick={handlepassword}
                    className="absolute top-0 right-0 flex justify-center items-center h-[100%] w-[60px] "
                  >
                    {visible ? (
                      <FontAwesomeIcon icon={faEye} size="sm" />
                    ) : (
                      <FontAwesomeIcon icon={faEyeSlash} size="sm" />
                    )}
                  </button>
                </div>

                <div className="flex items-center gap-2 mt-2 mx-2">
                  <input
                    type="checkbox"
                    id="remember"
                    className=" focus:outline-none w-3 h-3 accent-gray-200/20 border border-gray-200/20 rounded-sm text-white"
                  />
                  <label htmlFor="remember" className="text-sm ">
                    Remember me
                  </label>
                </div>

                <div className="mt-5">
                  <button onClick={handleSubmit} className="w-full text-white md:p-1.5 p-1 rounded-xl font-semibold md:text-lg bg-gradient-to-r from-[#3AB6FF] via-[#004696] to-[#FF3131] ">
                    Login
                  </button>
                </div>
                <div className="mt-2 mx-2">
                  <button  className="text-sm" onClick={() => setForgotPassword(true) } >Forgot password?</button>

                </div>

                {/* <div className="flex justify-center mt-5">
                  <p className="text-[#1ABDE1] relative md:before:w-36 before:w-28 before:block before:h-0.5 before:bg-[#CECECE] before:absolute before:top-3 before:left-9 md:after:w-36 after:w-28 after:block after:h-0.5 after:bg-[#CECECE] after:absolute after:top-3 after:right-9">
                    Or
                  </p>
                </div>

                <div className="flex justify-center gap-5 mt-5">
                  <img
                    src="../assets/devicon_google.png"
                    className="md:w-9 w-7"
                  />
                  <img
                    src="../assets/logos_facebook.png"
                    className="md:w-9 w-7"
                  />
                  {isDarkMode ?

                    <img src="../assets/bi_github.png" className="md:w-9 w-7" />
                    :
                    <img src="../assets/github.png" className="md:w-9 w-7" />

                  }
                </div> */}




                {/* <div className="flex justify-center mt-10 text-sm gap-3">
                  <p>Don’t have an account ?</p>
                  <a href="#">Signup</a>
                </div> */}
                <div className="w-full flex flex-wrap gap-3 justify-around text-sm mt-5">
                  <p>Terms & Conditions</p>
                  <p>Support</p>
                  <p>Customer Care</p>
                </div>
              </div>


                   {forgotpassword && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-10 backdrop-blur-md z-50">
              <div className="relative bg-white/25 rounded-2xl p-8 w-full max-w-sm md:max-w-md lg:max-w-lg shadow-lg">
                {/* Close Button */}
                <button
                  onClick={() => setForgotPassword(false)}
                  className="absolute top-3 right-4 text-white text-xl"
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
          
                {/* Content */}
                <div className="flex flex-col items-center">
                  <label className="text-white font-bold text-lg mb-2 self-start">
                    Enter Email
                  </label>
                  <input
                    type="text"
                    name="email"
                    onChange={handleInput}
                    required
                    className="w-full px-4 py-2 bg-transparent border border-gray-300 text-white rounded-lg text-sm focus:outline-none"
                  />
          
                  {/* Reset Button */}
                  <button
                    onClick={handleResetPassword}
                    className="w-full mt-5 px-6 py-3 text-white font-bold rounded-xl bg-gradient-to-br from-[#3AB6FF] via-[#004696] to-[#FF3131] to-80% flex justify-center items-center"
                    disabled={loading}
                  >
                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin className="mr-2" />
                    ) : (
                      "Reset Password"
                    )}
                  </button>
                </div>
              </div>
            </div>
          )}
          {checkmail && (
             <div className=' rounded-2xl z-50 w-full modal-overlay fixed inset-0 flex flex-col items-center justify-center bg-black bg-opacity-10 backdrop-blur-md'>
              <div className='lg:w-[30%] flex flex-col justify-center bg-white/25 p-10 rounded-lg relative'>
                <p className='text-white text-center'>Check your mail</p>
                <div className='flex justify-between gap-10'>
               
                <a href="https://mail.google.com" 
                 className='w-[50%] text-white mt-5 px-5 py-2 text-sm font-bold rounded-xl bg-gradient-to-br from-[#3AB6FF] via-[#004696] to-[#FF3131] to-80%'
                target="_blank">
             <p className='text-center'> Check Mail </p>    
                </a>
              
                <button
                  onClick={handleOpenModal}
                  className='w-[50%] text-white mt-5 px-5 py-2 text-sm border font-bold rounded-xl '
                >
                  Close
                </button>
                </div>
              </div>
              </div>
          )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
